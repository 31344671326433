<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    page: {
        title: "Edit Konfigurasi MUK",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Edit Konfigurasi MUK",
            items: [
                {
                    text: "Konfigurasi",
                    href: "/",
                },
                {
                    text: "Konfigurasi MUK",
                    href: "/konfig-muk",
                },
                {
                    text: "Edit Konfigurasi MUK",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsSkemaSertifikasi: [],
            optionsDokumen: [],
            meta_dokumen: [],

            // variable Page
            skema_sertifikasi_selected: "",
            muk_id: this.$route.params.id,
            kode_muk: null,
            optionsMetodeSertifikasi: [],
            metode_sertifikasi_selected: [],
        };
    },
    mounted() {
        let self = this;
        self.getSkemaSertifikasi();
        self.getDokumenMUK();
        self.getDataMetodeSertifikasi();

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        // get data elemen kompetensi berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-muk-detail/show-by-id",
            params: {
                id: self.muk_id,
            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix;
                    self.kode_muk = data_edit.muk.kode_muk;
                    console.log(data_edit);
                    self.skema_sertifikasi_selected = data_edit.muk.skema;
                    self.metode_sertifikasi_selected = data_edit.muk.metode_sertifikasi;
                    if (data_edit) {
                        let clear_data_data_edit_dokumen = [];
                        $.each(data_edit.dokumen, function (indexInArray, valueOfElement) {
                            clear_data_data_edit_dokumen.push({
                                dokumen: valueOfElement.dokumen,
                                urutan: valueOfElement.urutan,
                            });
                        });
                        self.meta_dokumen = clear_data_data_edit_dokumen;
                    }
                    // self.nama_elemen_kompetensi = data_edit.nama_elemen_kompetensi;
                    Swal.close();
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
    },
    methods: {
        addDokumen() {
            this.meta_dokumen.push({
                dokumen: "",
                urutan: "",
            });
        },
        removeDokumen(index) {
            this.meta_dokumen.splice(index, 1);
        },
        getDataMetodeSertifikasi() {
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-metode-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsMetodeSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        previewDokumenMUK(index, dokumen_id) {
            let self = this;
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/preview-konfig-template?dokumen_id=" + dokumen_id + "&skema_id=" + self?.skema_sertifikasi_selected?.id_skema_sertifikasi, "_blank");
        },
        getDokumenMUK() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-dokumen-muk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsDokumen = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-muk/update",
                data: {
                    id: self.muk_id,
                    kode_muk: self.kode_muk,
                    skema_id: self.skema_sertifikasi_selected?.id_skema_sertifikasi,
                    skema_nama: self.skema_sertifikasi_selected?.nama_skema_sertifikasi,
                    dokumen_meta: JSON.stringify(self.meta_dokumen),
                    metode_sertifikasi: JSON.stringify(self.metode_sertifikasi_selected),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman konfigurasi muk segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.$router.push({ name: "konfig-muk" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        {{ errorArray }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Kode MUK</label>
                                        <input type="text" v-model="kode_muk" class="form-control" />
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Skema Sertifikasi</label>
                                        <v-select :options="optionsSkemaSertifikasi" label="nama_skema_sertifikasi" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Metode Sertifikasi</label>
                                        <v-select :options="optionsMetodeSertifikasi" label="nama_metode_sertifikasi" v-model="metode_sertifikasi_selected" placeholder="Pilih Metode Sertifikasi"></v-select>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Dokumen</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>Urutan</th>
                                                    <th>Preview</th>
                                                    <th>
                                                        <button type="button" class="btn btn-sm btn-success" @click="addDokumen()"><i class="fa fa-plus"></i></button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_dokumen.length == 0">
                                                    <td colspan="4">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in meta_dokumen" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>
                                                        <v-select :options="optionsDokumen" label="nama_dokumen_muk" v-model="item.dokumen" placeholder="Pilih Dokumen"></v-select>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control" v-model="item.urutan">
                                                    </td>
                                                    <td>
                                                        <button type="button" v-on:click="previewDokumenMUK(index, item?.dokumen?.id)" v-if="item?.dokumen?.id" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview Dokumen</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-sm btn-danger" @click="removeDokumen(index)"><i class="fa fa-times"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button type="reset" class="m-1" variant="danger"><i class="fas fa-redo-alt"></i> Reset</b-button>
                                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan Konfigurasi MUK</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
